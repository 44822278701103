import React, { Component } from 'react';
import Form from './component/Form'
import './App.css'
import {BrowserRouter as Router,Route, Switch} from 'react-router-dom'

class App extends Component{

    render(){
        return(
            <Router>
            <div className="container-fluid">
              <div className="row">
              <Switch>
              <Route exact path="/form" component={Form}/>
              <Route exact path="/**" component={Form}/>
              </Switch>
              </div>
            </div>
            </Router>
        )
    }


}

export default App;


///--- Select the course first ---