import React, { Component, Fragment } from "react";
import {BeatLoader,BarLoader} from 'react-spinners'
import Side from './Side'
import 'react-phone-input-2/lib/style.css'
import SyncLoader from "react-spinners/SyncLoader";
import PhoneInput from 'react-phone-input-2'
import getURLParameter from '../getURLParameter'
import { FcIdea } from 'react-icons/fc';
const host = "api-in21.leadsquared.com";
const AccessKey = "u$r6ca453e436e25bd7021e8c66c2ca4197";
const SecretKey = "0f49a3202ab0be9e0d61992c3381a57c89bcbe90";
const url = `https://${host}/v2/LeadManagement.svc/Lead.Capture?accessKey=${AccessKey}&secretKey=${SecretKey}`;


const background="./bg.png"
const formBackground="./formBg.png"
const style={
  
    
    padding:"4%",
    marginBottom:"4%",
    backgroundImage: `url(${background})`,
    backgroundColor:"	#f9f9f9",
    backgroundSize: "cover",

  
}
const formStyle={
  
  
  
  
  backgroundImage: `url(${formBackground})`,
  backgroundClip: "padding-box",
  backgroundSize: "100% 100%",
  padding:"3%"
  


}

class Form extends Component {
  state = {
    form: {
      parentName: "",
      childName: "",
      email: "",
      contact_no: "",
      childGrade:"",
      referral_contact_no: "",
      referralName:"",
      checkBox:false,
      referralEmail:""
    },
    formErrorMessage: {
      parentNameError: "",
      contactError: "",
      EmailError: "",
      childNameError: "",
      referralNameError:"",
      referralEmailError:""
    },

    formValid: {
      childNameValid: false,
      contactValid:  false,
      referralContactNovValid:false,
      Email:  false,
      valid:  false,
      parentNameValid: false,
      referralNameValid: true,
      referralEmailValid:true,
    },
    submitted:false,
    errorMessage: "",
    successMessage: "",
    coursesList:"",
    loader:false,
    redirect:false,
    selectedButton:0,
    loader:false
  };
  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const { form } = this.state;
    this.setState({
      form: { ...form, [name]: value },
    });
    this.validateField(name, value);
  };

  componentDidMount=()=>{
    const { form } = this.state;
    if(form.childGrade){
      this.setState({loader:true},()=>this.getCourses(form.childGrade))
    }
  }

  selectGrade=(grade,button)=>{
    const { form } = this.state;
    
    this.setState({
      form: { ...form, childGrade: grade },selectedButton:button
    },()=>{
      this.validateField()
    });
  }

  validateField = (fieldName, value) => {
    const { formValid, formErrorMessage,form} = this.state;
    switch (fieldName) {
      case "parentName":
        if (value.length === 0) {
          formValid.parentNameValid = false;
          formErrorMessage.parentNameError = "Field Required";
        } else if (!value.match(/^[A-Za-z " "]{1,30}$/)) {
          formValid.parentNameValid = false;
          formErrorMessage.parentNameError = "Please enter a valid Parent Name";
        } else {
          formValid.parentNameValid = true;
          formErrorMessage.parentNameError = "";
          
        }
        break;
        case "referralName":
          if (value.length === 0) {
            formValid.referralNameValid = true;
            formErrorMessage.referralNameError = "";
          } 
          else if (!value.match(/^[A-Za-z " "]{1,30}$/)) {
          formValid.referralNameValid = false;
          formErrorMessage.referralNameError = "Please enter a valid Referral Name";
        } else {
          formValid.referralNameValid = true;
          formErrorMessage.referralNameError = "";
          
        }
        break;
      case "childName":
        if (value.length === 0) {
          formValid.childNameValid = false;
          formErrorMessage.childNameError = "Field Required";
        } else if (!value.match(/^[A-Za-z " "]{1,30}$/)) {
          formValid.childNameValid = false;
          formErrorMessage.childNameError = "Please enter a valid Child Name";
        } else {
          formValid.childNameValid = true;
          formErrorMessage.childNameError = "";
        }
        break;

      case "email":
        if (value.length === 0) {
          formValid.Email = false;
          formErrorMessage.EmailError = "Field Required";
        } else if (
          !value.match(
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
          )
        ) {
          formValid.Email = false;
          formErrorMessage.EmailError = "Please enter a valid Email";
        } else {
          formValid.Email = true;
          formErrorMessage.EmailError = "";
        }
        break;

        case "referralEmail":
        if (value.length === 0) {
          formValid.referralEmailValid = true;
          formErrorMessage.referralEmailError = "";
        } else if (
          !value.match(
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
          )
        ) {
          formValid.referralEmailValid = false;
          formErrorMessage.referralEmailError = "Please enter a valid Email";
        } else {
          formValid.referralEmailValid = true;
          formErrorMessage.referralEmailError = "";
        }
        break;

        case "contact_no":
          if(value.length===0){
            formValid.contactValid=false
          }
          else formValid.contactValid=true
           
          break;
          case "referral_contact_no":
            if(value.length===0){
              formValid.referralContactNoValid=false
            }
            else formValid.referralContactNoValid=true
             
          break;
          


    }
    if (
      formValid.parentNameValid &&
      formValid.Email &&
      formValid.childNameValid &&
      formValid.referralNameValid &&
      form.childGrade.length &&
      formValid.contactValid &&
      formValid.referralContactNoValid &&
      formValid.referralEmailValid
    ) {
      formErrorMessage.parentNameError = "";
      formErrorMessage.childNameError = "";
      formErrorMessage.EmailError = "";
      formErrorMessage.referralNameError = "";
      formErrorMessage.referralEmailError="";
      this.state.errorMessage=""
      formValid.valid = true;

    } else {
      // console.log(form.childGrade,String(form.referral_contact_no).length,String(form.contact_no).length)
      formValid.valid = false;
    }
  };

  

  nextStep=()=>{
    var {formValid,form} =this.state
    if(this.state.form.parentName === "") return this.setState({errorMessage: 'Please Enter a Parent Name'})
    if(this.state.form.childName === "") return this.setState({errorMessage: 'Please Enter your Child Name'})
    if(this.state.form.email === "") return this.setState({errorMessage: 'Please Enter your Email ID'})
    if(this.state.form.contact_no === "") return this.setState({errorMessage: 'Please Enter your Contact Number'})
    if(this.state.form.referral_contact_no === "") return this.setState({errorMessage: 'Please Enter your Referral Number'})
    if(this.state.form.childGrade === "") return this.setState({errorMessage: "Please Select a Grade"})
    
    if (formValid.valid) { 
      const payload = [


        {
          Attribute: "FirstName",
          Value: form.parentName,
        },
        {
          Attribute: "Phone",
          Value: form.contact_no,
        },
        {
          Attribute: "EmailAddress",
          Value: form.email,
        },
        {
          Attribute: "mx_Child_Grade",
          Value: form.childGrade,
        },
        
        {
          Attribute: "mx_Child_Name",
          Value: form.childName
        },
        {
          Attribute: "mx_Referral_Number",
          Value: form.referral_contact_no
        },
        {
          Attribute: "mx_Referral_Name",
          Value: form.referralName
        },
        {
          Attribute: "mx_Referral_Demo_Request",
          Value: form.checkBox
        },
        
        {
          Attribute: "mx_Referral_Email",
          Value: form.referralEmail
        },
        {
          Attribute: "Source",
          Value:"Referral Site",
        },
        {
          Attribute: "SourceMedium",
          Value: getURLParameter("utm_medium"),
        },
        {
          Attribute: "SourceCampaign",
          Value: getURLParameter("utm_campaign"),
        },
        {
          Attribute: "mx_keyword",
          Value: getURLParameter("utm_keyword"),
        } 
      ];

      this.pushToLeadsquad(payload)
  
    }
    else {
      this.setState({errorMessage:"Please fill all the required fields"})
    }
  }

  pushToLeadsquad=(payload)=>{
    this.setState({loader:true},()=>{

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log(data,"console-test-success");
          if(data.Status==="Error")
            {
              this.setState({loader:false,errorMessage:"Something Went Wrong"})
            }
           else
             {
              this.setState({loader:false,submitted:true})
            }
        })
        .catch((err) => {
          console.log(err,"console-test-error");
        });

    })
    

  
  
    
    
  }
  handleNumberChange=(name,value)=>{
      var {form} = this.state
      this.setState({
        form: { ...form, [name]: value },
      });
      this.validateField(name,value)

  }


  render() {
var  { parentName,
    childName,
    email,
    contact_no,
    childGrade,
    referral_contact_no,referralName,checkBox,referralEmail}=this.state.form

    var {
      parentNameError,
      childNameError,
      EmailError,
      referralNameError,
      referralEmailError
    } = this.state.formErrorMessage;
    var {valid}=this.state.formValid
    var {selectedButton,errorMessage}=this.state

    
    
    // if(this.state.redirect){
    //   var utm={
    //     source:getURLParameter("utm_source"),
    //     medium:getURLParameter("utm_medium"),
    //     campaign:getURLParameter("utm_campaign"),
    //     keyword:getURLParameter("utm_keyword")

    //   }
      
    //   return <Redirect to="/pick-a-slot"/>
    // }
    return (
      <Fragment>
        <div className="col-lg-7 col-12 d-none d-lg-block">
        <Side/>
        </div>
       
        <div
          className="col-lg-5 col-12 mb-lg-0   ml-lg-auto  body"
          style={style}
        >
        
         {!this.state.submitted && <div className="row  d-lg-none mb-3 " >
            <div className="col-10 offset-1">
            <h3  className="font-weight-bold mb-3 text-center">How do Referrals work?</h3>
            <div className="row p-3">
            
            <div className="col-12 text-center  font-weight-bold  pt-2 mb-2 " style={{backgroundColor:"#FE672E"}}><h5 className="text-white">For Getting Referrals</h5></div>
            <div className="col-4  text-secondary font-weight-bold text-center p-1   border-right border-dark" style={{backgroundColor:"#F3F3F3"}}><h6>1 Student</h6></div>
            <div className="col-8  text-secondary font-weight-bold   p-1 " style={{backgroundColor:"#F3F3F3"}}><h6 style={{marginLeft:"20%"}} >Get an Amazon voucher worth <span className="font-weight-bold text-dark">₹1,000</span></h6></div>
            <div className="col-4  text-secondary font-weight-bold text-center p-1   border-right border-dark" style={{backgroundColor:"#F3F3F3"}}><h6>2 Student</h6></div>
            <div className="col-8  text-secondary font-weight-bold  p-1 " style={{backgroundColor:"#F3F3F3"}}><h6 style={{marginLeft:"20%"}} >Get <span className="font-weight-bold text-dark">FREE</span> upgrade to the next course</h6></div>
            <div className="col-4  text-secondary font-weight-bold text-center p-1   border-right border-dark" style={{backgroundColor:"#F3F3F3"}}><h6>More Than 15 Student</h6></div>
            <div className="col-8  text-secondary font-weight-bold  p-1 " style={{backgroundColor:"#F3F3F3"}}><h6 style={{marginLeft:"20%"}}>Get an <span className="font-weight-bold text-dark">iPad,Laptop </span>and many more exciting stuffs</h6></div>
            <div className="col-12 text-center  font-weight-bold  pt-2 mb-2 mt-2" style={{backgroundColor:"#FE672E"}}><h5 className="text-white">For Joining through Referral</h5></div>
            
            <div className="col-12  text-secondary font-weight-bold text-center   " style={{backgroundColor:"#F3F3F3"}}><h6 >Get an Amazon voucher worth <span className="font-weight-bold text-dark">₹1,000</span></h6></div>
            </div>
            </div>
            
            
            </div>
         } 
         
          <div className="col-10 offset-1 col-lg-8 offset-lg-2 text-center   text-lg-center text-muted mb-3 font-weight-bold">
          {!this.state.submitted && "Fill out this form to Get the Referral Offer"}
          </div>
          
          {!this.state.submitted ?(<div
            className="col-lg-10 offset-lg-1 col-10 offset-1 shadow-lg "
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              "border-radius": "15px",
              opacity: 1,
              ...formStyle
            }}
          >
            <div
              className="col-lg-10 offset-lg-1  text-center"
              style={{
                font: "normal normal bold 25px/50px Montserrat",
                "letter-spacing": "0.50px",
                color: "#141414",
                "text-transform": "capitalize",
                opacity: 1,
                
              }}
            >
              Join the Codeyoung Family
            </div>

            <form style={{ padding: "5%" }}>
              <div className="form-group">
                <label className="font-weight-bold">Parent Name: <sup className="text-danger">*</sup> </label>
                <input  data-toggle="tooltip" data-placement="top" title="Please Enter the Parent Name" type="text" placeholder="Enter your name here" className={!parentNameError?("form-control"):("form-control is-invalid")} name="parentName" value={parentName} onChange={(e) => this.handleChange(e)} />
            {   parentNameError && <div class="invalid-feedback font-weight-bold">{parentNameError}</div>}
              </div>
              <div className="form-group">
                <label className="font-weight-bold">Child's Name: <sup className="text-danger">*</sup> </label>
                <input data-toggle="tooltip" data-placement="top" title="Please Enter the Child Name" placeholder="Enter your child's name here" type="text" value={childName} className={!childNameError?("form-control"):("form-control is-invalid")} name="childName" onChange={(e) => this.handleChange(e)}/>
                {   childNameError && <div class="invalid-feedback font-weight-bold">{childNameError}</div>}
              </div>
            
              <div className="form-group">
                <label className="font-weight-bold">Parent's Email Address: <sup className="text-danger">*</sup> </label>
                <input data-toggle="tooltip" data-placement="top" title="Please Enter the Email" type="text" placeholder="Enter your personal email ID here" value={email} className={!EmailError?("form-control"):("form-control is-invalid")} name="email" onChange={(e) => this.handleChange(e)}/>
                {   EmailError && <div class="invalid-feedback font-weight-bold">{EmailError}</div>}
              </div>
              <div className="form-group mb-1">
                <label className="font-weight-bold">Parent's Phone Number: <sup className="text-danger">*</sup> </label>
              </div>
              
                <div className="form-group">
                <PhoneInput
                    country={'in'}
                    
                    countryCodeEditable={false}
                    inputStyle={{width:"100%"}}
                    dropdownStyle={{color:"orange"}}

                    defaultErrorMessage="Please enter"
                      name="Phone"
                      required
                      value={contact_no}
                      min="0"
                      placeholder="Contact"
                      onChange={(contact_no) => this.handleNumberChange("contact_no",contact_no)}
                      required
                    />
                </div>
              
              <div className="form-group mb-2">
                <label className="font-weight-bold">Select Child's Grade: <sup className="text-danger">*</sup> </label>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3 ">
                  <button type="button" className={selectedButton===1 || childGrade=== "1-2" ?("btn btn-dark btn-block"):("btn btn-outline-secondary btn-block")} onClick={(grade,button)=>this.selectGrade("1-2",1)}>1-2</button>
                </div>

                <div className="form-group col-lg-3 ml-auto">
                <button type="button" className={selectedButton===2 || childGrade=== "3-5" ?("btn btn-dark btn-block"):("btn btn-outline-secondary btn-block")} onClick={(grade,button)=>this.selectGrade("3-5",2)}>3-5</button>
                </div>

                <div className="form-group col-lg-3 ml-auto">
                <button type="button" className={selectedButton===3 || childGrade=== "6-8" ?("btn btn-dark btn-block"):("btn btn-outline-secondary btn-block")} onClick={(grade,button)=>this.selectGrade("6-8",3)}>6-8</button>
                </div>

                <div className="form-group col-lg-3 ml-auto">
                <button type="button" className={selectedButton===4 || childGrade=== "9-12" ?("btn btn-dark btn-block"):("btn btn-outline-secondary btn-block")} onClick={(grade,button)=>this.selectGrade("9-12",4)}>9-12</button>
                </div>
              </div>
              <div className="form-group text-muted border border-muted rounded">
               <div className="row">
                 <div className="col-2   p-1 pl-lg-4 pl-3 pb-2" ><FcIdea size="2em"/></div>
                 <div className="col-10  font-weight-bold" style={{fontSize:"10px",margin:"auto"}}>
                 Please share details of your reference from the Codeyoung family to unlock goodies and offers!
                 </div>
                 </div> 
              
              </div> 
              <div className="form-group mb-2">
                <label className="font-weight-bold "> Referral Number: <sup className="text-danger">*</sup> </label>
              </div>
              
                <div className="form-group">
                <PhoneInput
                    country={'in'}
                    countryCodeEditable={false}
                    inputStyle={{width:"100%"}}
                    dropdownStyle={{color:"orange"}}

                    defaultErrorMessage="Please enter"
                      name="Phone"
                      required
                      value={referral_contact_no}
                      min="0"
                      placeholder="Referral Contact"
                      onChange={(referral_contact_no) => this.handleNumberChange("referral_contact_no",referral_contact_no)}
                      required
                    />
                </div>
                
                <div className="form-group">
                <label className="font-weight-bold">Referral's Email Address:</label>
                <input data-toggle="tooltip" data-placement="top" title="Please Enter the Referral Email" type="text" placeholder="Enter your Referral email ID here" value={referralEmail} className={!referralEmailError?("form-control"):("form-control is-invalid")} name="referralEmail" onChange={(e) => this.handleChange(e)}/>
                {   referralEmailError && <div class="invalid-feedback font-weight-bold">{referralEmailError}</div>}
              </div>

                <div className="form-group">
                <label className="font-weight-bold">Referral Name:</label>
                <input  data-toggle="tooltip" data-placement="top" title="Please Enter the Referral Name" type="text" placeholder="Enter your Referral Name here" className={!referralNameError?("form-control"):("form-control is-invalid")} name="referralName" value={referralName} onChange={(e) => this.handleChange(e)} />
            {   referralNameError && <div class="invalid-feedback font-weight-bold">{referralNameError}</div>}
              </div>
          
              <div className="form-check form-check-inline mb-4">
              <input className="form-check-input mr-2" name="checkBox" type="checkbox"  value={checkBox} onChange={() => this.setState({
      form: { ...this.state.form, checkBox: ! checkBox},
    })} />
              <label className="form-check-label mt-2" ><h6>I want a Demo class before registering.</h6></label>
              </div>
              <div className="form-group">
              <button type="button" style={{backgroundColor:"#FFEB00"}} onClick={()=>this.nextStep()} disabled={this.state.loader} className="btn  btn-block font-weight-bold"> <SyncLoader
 size={15}color={"#000000"}loading={this.state.loader}/> {!this.state.loader && "Submit"}  </button>
              {errorMessage && <p className="text-danger font-weight-bold text-center mt-2">{errorMessage}</p>}
              </div>
              
            </form>
            
          </div>):(<div
            className="col-lg-10 offset-lg-1 col-10 offset-1 shadow-lg"
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              "border-radius": "15px",
              opacity: 1,
              paddingBottom:"5%",
              marginTop:"auto",
              height:"auto"
            }}
          >
            <div className="col-12 p-md-4 p-lg-0 p-sm-4">
              <img src="./positive.png" className="rounded col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-4 offset-sm-4 col-6 offset-3 text-center mb-lg-3 mb-lg-4 mb-3 mt-lg-4"/>
            </div>
            <div className="text-center font-weight-bold mb-lg-4 mb-md-4 mb-2">
            Thank You!!  We’ve received your registration and our team will get in touch with you to take things forward. For any support write to <a href="https://support@smaowl.com">support@smaowl.com</a>
            </div>
            <a href="https://www.codeyoung.com" target="_blank" type="button" className="btn font-weight-bold btn-block col-12 col-lg-8 offset-lg-2 col-sm-8 offset-sm-2 col-md-8 offset-md-2   " style={{backgroundColor:"#FFEB00"}}>     
                Explore more on our Website
                 </a>
            </div>
          )}
          <div className="col-10 offset-1 col-lg-8 offset-lg-2  text-center text-right font-weight-bold " style={{marginTop:"10%",marginBottom:"10%"}}>
            <h6 className="text-white"></h6>
            
          </div>
        </div>
        <div className="col-lg-7 col-12 d-lg-none">
        <Side/>
        </div>
      </Fragment>
    );
  }
}


export default Form;
