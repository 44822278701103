import React, { Component, Fragment } from "react";
import './side.css'

const background="./banner2.Bg.png"
var style={
    backgroundImage: `url(${background})`,
    backgroundSize: "100% 100%",
    width:"100%",
    height:"20vh"
    
}

class Side extends Component{
    render(){
        return(
            
             <div className="col-lg-10 offset-lg-1 col-12">
                 
              <a href="https://www.codeyoung.com" className="d-none d-lg-block">  <img src="./primarylogo.png" className="col-4   mt-2" style={{marginBottom:"6%"}}/></a>
                <div className="col-12  col-lg-12 offset-lg-0 text-lg-left text-center ">
            
            
            <img className="col-12 p-0 img-fluid " src="./banner2.Bg.png"/>
            
            <div className="row mt-4 d-none d-lg-block" style={{marginTop:"5%"}}>
            <div className="col-12">
            <h3  className="font-weight-bold mb-3">How do Referrals work?</h3>
            <div className="row p-3">
            
            <div className="col-12 text-center  font-weight-bold   pt-2 mb-2" style={{backgroundColor:"#FE672E"}}><h5 className="text-white">For Getting Referrals</h5></div>
            <div className="col-4  text-secondary font-weight-bold text-center p-1   border-right border-dark" style={{backgroundColor:"#F3F3F3"}}><h6>1 Student</h6></div>
            <div className="col-8  text-secondary font-weight-bold   p-1 " style={{backgroundColor:"#F3F3F3"}}><h6 style={{marginLeft:"20%"}} >Get an Amazon voucher worth <span className="font-weight-bold text-dark">₹1,000</span></h6></div>
            <div className="col-4  text-secondary font-weight-bold text-center p-1   border-right border-dark" style={{backgroundColor:"#F3F3F3"}}><h6>2 Student</h6></div>
            <div className="col-8  text-secondary font-weight-bold  p-1 " style={{backgroundColor:"#F3F3F3"}}><h6 style={{marginLeft:"20%"}} >Get <span className="font-weight-bold text-dark">FREE</span> upgrade to the next course</h6></div>
            <div className="col-4  text-secondary font-weight-bold text-center pt-2   border-right border-dark" style={{backgroundColor:"#F3F3F3"}}><h6>More Than 15 Student</h6></div>
            <div className="col-8  text-secondary font-weight-bold  p-1 " style={{backgroundColor:"#F3F3F3"}}><h6 style={{marginLeft:"20%"}}>Get an <span className="font-weight-bold text-dark">iPad,Laptop </span>and many more exciting stuffs</h6></div>
            <div className="col-12 text-center  font-weight-bold  pt-2 mb-2 mt-2" style={{backgroundColor:"#FE672E"}}><h5 className="text-white">For Joining through Referral</h5></div>
            
            <div className="col-12  text-secondary font-weight-bold text-center   " style={{backgroundColor:"#F3F3F3"}}><h6 >Get an Amazon voucher worth <span className="font-weight-bold text-dark">₹1,000</span></h6></div>
            </div>
            </div>
            
            
            </div>
            <div className="row" style={{marginTop:"5%"}}>
            <div className="col-12">
            <p  className="font-weight-bold mb-3 pl-0 heading">Coding Is Important For The Young Minds</p>
            <div className="row">
            <div className="col-lg-6 col-12">
                <div className="row">
                <img src="./math.png" className="col-4 rounded"/>
                <div className="col-8 text-muted pt-4 font-weight-bold ">Improves Math Skills</div>
                </div>
            </div>
            <div className="col-lg-6 col-12">
                <div className="row">
                <img src="./problemS.png" className="col-4 rounded"/>
                <div className="col-8 text-muted p-4 font-weight-bold">Boosts Problem-Solving Skills</div>
                </div>
            </div>
            <div className="col-lg-6 col-12">
                <div className="row">
                <img src="./creative.png" className="col-4 img-fluid"/>
                <div className="col-8 text-muted p-4 font-weight-bold ">Encourages Creativity</div>
                </div>
            </div>
            <div className="col-lg-6 col-12">
                <div className="row">
                <img src="./academic.png" className="col-4 rounded"/>
                <div className="col-8 text-muted p-4 font-weight-bold ">Improves Academic Performance</div>
                </div>
            </div>
            <div className="col-lg-6 col-12">
                <div className="row">
                <img src="./newtech.png" className="col-4 rounded"/>
                <div className="col-8 text-muted p-4 font-weight-bold ">Understanding Of New Technologies</div>
                </div>
            </div>
            <div className="col-lg-6 col-12">
                <div className="row">
                <img src="./analytical.png" className="col-4 img-fluid"/>
                <div className="col-8 text-muted p-4 font-weight-bold ">Improves Analytical Skills</div>
                </div>
            </div>
            </div>
            </div>
            </div>
            
          </div>
            <div className=" col-12   " style={{marginTop:"5%"}}>
            <p  className="font-weight-bold mb-3 text-center heading text-lg-left text-md-left">Our Happy Customers</p>
            <div className="row">
            <div className="col-lg-6 col-md-6">
            <img src="./parent1.png" className="rounded col-4 ml-0 p-0 mb-3"/>
            <h6 className="font-weight-bold">Fatema Arsiwala</h6>
            <h6 className="font-weight-bold">Mustafa's Mother</h6>
            <p className="text-muted font-weight-bold">"The sessions are interactive and challenging. Our experience with Codeyoung has been amazing so far."

            </p>
            </div>
            <div className="col-lg-6 col-md-6">
            <img src="./parent2.png" className="rounded col-4 ml-0 p-0 mb-3"/>
            <h6 className="font-weight-bold">Vaishali Thakkar</h6>
            <h6 className="font-weight-bold mt-0">Divyana's Mother</h6>
            <p className="text-muted font-weight-bold">
            "My 8-year-old daughter is really enjoying the Scratch course. I am really happy and looking forward to many more exciting sessions."
            </p>
            </div>
            </div>
            </div>
            
            </div>

        )
    }
}

export default Side;