const getURLParameter = (param) => {
    var withoutOriginURL = window.location.href.substring((window.location.origin).length+1);

    if(withoutOriginURL.split("?")[1]){
        var urlVariableString = withoutOriginURL.split("?")[1].split("&");
    for (var i = 0; i < urlVariableString.length; i++){
        var parameterName = urlVariableString[i].split('=');
        if (parameterName[0] == param) 
        {
            return parameterName[1];
        }
    }
    return ""
    }
    else return ""
    
}

export default getURLParameter;