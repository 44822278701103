import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import getURLParameter from './getURLParameter'
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
  gtmId: 'GTM-TH4RWGG',
  dataLayer: {
    "event":"CY_Diwali2020ReferralPageLanding",
    "source":getURLParameter("utm_source"),
    "medium":getURLParameter("utm_medium"),
    "campaign":getURLParameter("utm_campaign"),
    "keyword":getURLParameter("utm_keyword"),
		"parent name":"",
    "email ID":"",
		"phone number":"",
		"User ID":"",
		IsMobile:"",
    grade:"",
    country:"",
    "Laptop Available":""
  }
}

console.log(tagManagerArgs)

TagManager.initialize(tagManagerArgs)



ReactDOM.render(
  
    <App  />,
  document.getElementById('root')
);

